import React, { Component,useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";
import Axios from "axios";
import "./scss/style.scss";
import * as actions from "./store/actions/actionCreators";

Axios.defaults.baseURL = "//grameenapi.softasiabd.com/api/";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() =>
  import("./containers/AdminContainer/TheLayout")
);

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

export default function App () {
  const dispatch = useDispatch();

  const checkForInactivity = () => {
    // get expire time from local storage
    const expireTime = localStorage.getItem("expireTime");
    // if expire time is earlier than now, log out
    if(expireTime < Date.now()) {
      const logged_in = false;
      dispatch(actions.setLogout(logged_in));
    }
  }

  const updateExpireTime = () => {
    // set expire time to 1 hour from now
    const expireTime = Date.now() + 1200000;

    // set expire time in local storage
    localStorage.setItem("expireTime", expireTime);
  }
  
  useEffect(() => {
    // check for inactivity in every 5 seconds
    const interval = setInterval(() => {
      checkForInactivity();
    }, 300000);

    // clear interval  on unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // set initial expire time
    
    checkForInactivity();
    updateExpireTime();

    // set Event listeners
    window.addEventListener("click", updateExpireTime);
    window.addEventListener("keypress", updateExpireTime);
    window.addEventListener("scroll", updateExpireTime);
    window.addEventListener("mousemove", updateExpireTime);

    // clean up
    return () => {
      window.removeEventListener("click", updateExpireTime);
      window.removeEventListener("keypress", updateExpireTime);
      window.removeEventListener("scroll", updateExpireTime);
      window.removeEventListener("mousemove", updateExpireTime);
    }
  }, []);
  return (
    <React.Suspense fallback={loading}>
      <Switch>
        <Route
          exact
          path="/"
          name="Login Page"
          render={(props) => <Login {...props} />}
        />
        <Route
          exact
          path="/register"
          name="Register Page"
          render={(props) => <Register {...props} />}
        />
        <Route
          exact
          path="/404"
          name="Page 404"
          render={(props) => <Page404 {...props} />}
        />
        <Route
          exact
          path="/500"
          name="Page 500"
          render={(props) => <Page500 {...props} />}
        />

        <Route
          path="/"
          name="Home"
          render={(props) => <TheLayout {...props} />}
        />
      </Switch>
    </React.Suspense>
  );
}

